"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fix_safari_display = void 0;
const fix_safari_display = () => {
    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!is_safari)
        return false;
    document.querySelectorAll(".no-safari-display").forEach((e) => {
        const ele = e;
        ele.style.display = "none";
        e = ele;
    });
    return true;
};
exports.fix_safari_display = fix_safari_display;
