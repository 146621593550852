"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ui_init_1 = require("./ui-init");
const trig_handler_1 = require("./trig-handler");
const video_controller_1 = require("./video-controller");
const safari_fix_1 = require("./safari-fix");
const init_ui = async () => {
    (0, ui_init_1.update_bg_container)();
    (0, ui_init_1.update_title_container)();
    const is_saf = (0, safari_fix_1.fix_safari_display)();
    document.querySelectorAll(".video-controller").forEach((e) => {
        (0, video_controller_1.add_video_control_elements)(`${e.id}`);
    });
    (0, ui_init_1.add_element_parallax)("bg-container", 0.4);
    (0, ui_init_1.add_element_parallax)("main-bg", 0.4);
    (0, ui_init_1.add_element_parallax)("glo2", -0.02, "-2rem");
    (0, ui_init_1.add_element_parallax)("glo1", -0.1);
    const vid_title_paraspeed = -0.7;
    let vid_title_topoffset = 0;
    let vid2_title_topoffset = 0;
    (0, ui_init_1.add_element_parallax)("vid-sec-title-container", 0);
    (0, ui_init_1.add_element_parallax)("vid2-sec-title-container", 0);
    (0, trig_handler_1.add_trigger_listener)("trig-1", "trig-alpha", [
        {
            scroll_past: () => {
                document.getElementById("about-content").classList.remove("disable-hidden");
                document.getElementById("title-text-container").classList.add("disable-hidden");
            },
            scroll_back: () => {
                document.getElementById("about-content").classList.add("disable-hidden");
                document.getElementById("title-text-container").classList.remove("disable-hidden");
            }
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)("trig-2", "trig-beta", [
        {
            scroll_past: () => {
                document.getElementById("title-theory").style.color = "#363636";
                document.getElementById("title-reality").style.color = "#f3f3f3";
            },
            scroll_back: () => {
                document.getElementById("title-theory").style.color = "#f3f3f3";
                document.getElementById("title-reality").style.color = "#363636";
            }
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)("trig-2", "trig-beta", [
        {
            scroll_past: () => {
                document.getElementById("vid-sep").classList.remove("disable-hidden");
                document.getElementById("vid-section").classList.remove("disable-hidden");
                setTimeout(() => {
                    document.getElementById("vid-sec-title").style.letterSpacing = "1pt";
                }, 150);
            },
            scroll_back: () => { }
        },
    ]);
    if (!is_saf)
        (0, trig_handler_1.add_trigger_listener)("trig-3", "trig-alpha", [
            {
                scroll_past: () => {
                    vid_title_topoffset = document.getElementById("main-page").scrollTop * vid_title_paraspeed -
                        (document.getElementById("trig-alpha").getBoundingClientRect().y -
                            document.getElementById("trig-3").getBoundingClientRect().y) * vid_title_paraspeed;
                    (0, ui_init_1.set_element_parallax)(4, vid_title_paraspeed, `${vid_title_topoffset}px`);
                },
                scroll_back: () => {
                    (0, ui_init_1.set_element_parallax)(4, 0);
                }
            },
        ]);
    (0, trig_handler_1.add_trigger_listener)("trig-4", "trig-beta", [
        {
            scroll_past: () => { document.getElementById("vid-sec-title-container").classList.add("disable-hidden"); },
            scroll_back: () => { document.getElementById("vid-sec-title-container").classList.remove("disable-hidden"); }
        },
    ]);
    if (!is_saf)
        (0, trig_handler_1.add_trigger_listener)("trig-6", "trig-alpha", [
            {
                scroll_past: () => {
                    vid2_title_topoffset = document.getElementById("main-page").scrollTop * vid_title_paraspeed -
                        (document.getElementById("trig-alpha").getBoundingClientRect().y -
                            document.getElementById("trig-6").getBoundingClientRect().y) * vid_title_paraspeed;
                    (0, ui_init_1.set_element_parallax)(5, vid_title_paraspeed, `${vid2_title_topoffset}px`);
                },
                scroll_back: () => {
                    (0, ui_init_1.set_element_parallax)(5, 0);
                }
            },
        ]);
    (0, trig_handler_1.add_trigger_listener)("trig-7", "trig-alpha", [
        {
            scroll_past: () => { document.getElementById("vid2-sec-title-container").classList.add("disable-hidden"); },
            scroll_back: () => { document.getElementById("vid2-sec-title-container").classList.remove("disable-hidden"); }
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)("trig-8", "trig-alpha", [
        {
            scroll_past: () => {
                document.getElementById("vid-section").classList.add("disable-hidden");
                document.getElementById("vid-sep-2").classList.add("disable-hidden");
            },
            scroll_back: () => {
                document.getElementById("vid-section").classList.remove("disable-hidden");
                document.getElementById("vid-sep-2").classList.remove("disable-hidden");
            },
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)("trig-9", "trig-alpha", [
        {
            scroll_past: () => {
                document.getElementById("host-section").classList.add("disable-hidden");
                document.getElementById("host-sep").classList.add("disable-hidden");
            },
            scroll_back: () => {
                document.getElementById("host-section").classList.remove("disable-hidden");
                document.getElementById("host-sep").classList.remove("disable-hidden");
            },
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)("trig-10", "trig-beta", [
        {
            scroll_past: () => {
                document.getElementById("pat-section").classList.add("disable-hidden");
            },
            scroll_back: () => {
                document.getElementById("pat-section").classList.remove("disable-hidden");
            },
        },
    ]);
    await (0, ui_init_1.fadein_background)();
    (0, ui_init_1.set_flicker)("glo1", true, "#b91ad8", Math.random() * 700 + 500);
    (0, ui_init_1.set_flicker)("glo2", true, "#84a4ff", Math.random() * 700 + 200, 3000, 6000);
};
window.onload = () => {
    const is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    if (is_mobile) {
        const k = document.querySelector("body");
        if (!k)
            return;
        k.style.width = `${window.innerWidth}px`;
        k.style.minHeight = `${window.innerHeight}px`;
        k.style.maxHeight = `${window.innerHeight}px`;
    }
    (0, video_controller_1.load_video)("nerf-video", "./assets/video/v2.mov", "trig-4", "trig-4.1", true);
    (0, video_controller_1.load_video)("anime-video", "./assets/video/v1.mov", "trig-5", "trig-5.1", true);
    (0, video_controller_1.load_video)("tutorial-video", "./assets/video/v3.mov", "trig-7", "trig-7.1", true);
    init_ui();
    (0, trig_handler_1.update_trigger_listener)();
    (0, ui_init_1.update_parallax)();
    document.getElementById("main-page").onscroll = (evt) => {
        (0, trig_handler_1.update_trigger_listener)();
        (0, ui_init_1.update_parallax)();
    };
    window.onresize = () => {
        (0, ui_init_1.update_bg_container)();
        (0, ui_init_1.update_title_container)();
        (0, trig_handler_1.update_trigger_listener)();
        (0, ui_init_1.update_parallax)();
        requestAnimationFrame(() => { });
    };
};
