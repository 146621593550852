"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_trigger_listener = exports.add_trigger_listener = void 0;
;
;
let trig_elements = [];
const add_trigger_listener = (trig_id, target_trig_id, elements, passed = false, passed2 = false) => {
    trig_elements.push({
        trig_id,
        target_trig_id,
        elements,
        passed,
        passed2,
    });
};
exports.add_trigger_listener = add_trigger_listener;
const update_trigger_listener = () => {
    for (let i = 0; i < trig_elements.length; i++) {
        let trig_id = trig_elements[i].trig_id;
        let target_trig_id = trig_elements[i].target_trig_id;
        let elements = trig_elements[i].elements;
        trig_elements[i].passed = document.getElementById(trig_id).getBoundingClientRect().y < document.getElementById(target_trig_id).getBoundingClientRect().y;
        if (trig_elements[i].passed) {
            if (!trig_elements[i].passed2) {
                for (let j = 0; j < elements.length; j++) {
                    elements[j].scroll_past();
                }
                trig_elements[i].passed2 = trig_elements[i].passed;
                continue;
            }
            else
                continue;
        }
        else {
            if (trig_elements[i].passed2) {
                for (let j = 0; j < elements.length; j++) {
                    elements[j].scroll_back();
                }
                trig_elements[i].passed2 = trig_elements[i].passed;
                continue;
            }
            else
                continue;
        }
        requestAnimationFrame(() => { });
    }
};
exports.update_trigger_listener = update_trigger_listener;
