"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.add_video_control_elements = exports.load_video = void 0;
const trig_handler_1 = require("./trig-handler");
const load_video = async (video_id, src, trig_id, trigend_id, pause = false) => {
    const ele = document.getElementById(video_id);
    if (!ele)
        return;
    ele.src = src;
    ele.load();
    if (pause)
        ele.pause();
    (0, trig_handler_1.add_trigger_listener)(trig_id, "trig-theta", [
        {
            scroll_past: () => { document.getElementById(video_id).play(); },
            scroll_back: () => { document.getElementById(video_id).pause(); }
        },
    ]);
    (0, trig_handler_1.add_trigger_listener)(trigend_id, "trig-omega", [
        {
            scroll_past: () => { document.getElementById(video_id).pause(); },
            scroll_back: () => { document.getElementById(video_id).play(); }
        },
    ]);
};
exports.load_video = load_video;
const add_video_control_elements = (id) => {
    const ctrl = document.getElementById(id);
    if (!ctrl)
        return;
    const vid_id = ctrl.getAttribute("ctrl-video-id");
    const vid_elmnt = document.getElementById(vid_id);
    if (!vid_elmnt)
        return;
    let paused = false;
    let update_visuals = true;
    ctrl.addEventListener("mouseenter", () => {
        ctrl.src = paused ? "./assets/img/vec/play-hr.svg" : "./assets/img/vec/pause-hr.svg";
    });
    ctrl.addEventListener("mouseleave", () => {
        ctrl.src = paused ? "./assets/img/vec/play-nm.svg" : "./assets/img/vec/pause-nm.svg";
    });
    ctrl.addEventListener("click", () => {
        paused = !paused;
        update_visuals = false;
        if (paused)
            vid_elmnt.pause();
        else
            vid_elmnt.play();
        ctrl.src = paused ? "./assets/img/vec/play-hr.svg" : "./assets/img/vec/pause-hr.svg";
        requestAnimationFrame(() => { });
        setTimeout(() => {
            update_visuals = true;
        }, 20);
    });
    vid_elmnt.addEventListener("play", () => {
        if (!update_visuals)
            return;
        paused = false;
        ctrl.src = paused ? "./assets/img/vec/play-nm.svg" : "./assets/img/vec/pause-nm.svg";
    });
    vid_elmnt.addEventListener("pause", () => {
        if (!update_visuals)
            return;
        paused = true;
        ctrl.src = paused ? "./assets/img/vec/play-nm.svg" : "./assets/img/vec/pause-nm.svg";
    });
};
exports.add_video_control_elements = add_video_control_elements;
